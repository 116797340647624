const cheerio = require('cheerio');


console.log('hello world')

const answerLinks = document.getElementById('answerLinks');
const buttonSubmit = document.getElementById('questionSubmit');
const questionInput = document.getElementById('questionInput');
buttonSubmit.addEventListener('click', function() {
  const parsed = cheerio.load(questionInput.value);

  /* <div id="question_1232303_question_text" class="question_text */

  // Get all questions
  const allQuestions = parsed('div.question_text');
  console.log(allQuestions);


  answerLinks.innerHTML = '';
  for (const element of allQuestions) {
    const questionSanitize = element.children[0].data.replaceAll('_','').trim();

    var newA = document.createElement('a');
    newA.setAttribute('href','https://www.google.com/search?q=' + encodeURIComponent('site: quizlet.com ' + questionSanitize));
    newA.setAttribute('target','_blank');
    newA.innerHTML = questionSanitize;

    var li = document.createElement('li');
    li.appendChild(newA)

    answerLinks.appendChild(li);
  }


});